export function useNavbar() {
  const showCurrencyPopup = ref(false)
  const showLocalePopup = ref(false)

  const { locales } = useNuxtApp().payload.data
  const { locale, changeLocale } = useLocale()
  const userInfoStore = useUserStore()
  const commonDataStore = useCommonStore()
  const supportDirectBooking = computed(() => !!commonDataStore.commonData?.partnerInfo?.supportDirectBooking)
  const currencyInfo = computed(() => commonDataStore.commonData?.currencyInfo || [])

  const currentCurrency = computed({
    get: () => userInfoStore.userInfo.currency,
    set: (value: string) => {
      fetchChageCurrency(value)

      userInfoStore.userInfo.currency = value
      showCurrencyPopup.value = false

    // 更新 URL 中的 currency 参数
    // router.replace({
    //   query: {
    //     ...route.query,
    //     currency: value,
    //   },
    // })
    },
  })

  const currentLocale = computed({
    get: () => locale.value,
    set: (value: string) => {
      showLocalePopup.value = false

      setTimeout(() => {
        changeLocale(value)
      }, 400)
    },
  })

  function fetchChageCurrency(currency: string) {
    const { $fetch } = useNuxtApp()

    $fetch('/api/currency-change', {
      method: 'post',
      body: {
        currency,
      },
    })
  }
  return {
    showCurrencyPopup,
    showLocalePopup,
    locales,
    currencyInfo,
    currentCurrency,
    currentLocale,
    supportDirectBooking,
  }
}
